import { users } from "./users";
import { bulk } from "./bulk";
import { credit } from "./credits/payments";
import { usage } from "./credits/expenses";
import {balance} from "./credits/balance";
import { admin, customer, guest } from "./roles";
import { participants } from "./participants";
import { tags } from "./participants/tags";
import { ForgotPasswordPage } from "../forgot";
import { SignUpPage } from "../signup";
import { UserProfile } from "./components/settings";
import SettingsIcon from '@mui/icons-material/Settings';

export function getResources(user) {
    if (user.role === admin) {
        return adminResources;
    }
    if (user.role === customer) {
        return customerResources;
    }
    if (user.role === guest) {
        return guestResources;
    }
}

export const adminResources = [   
    participants,
    bulk,
    credit,
    usage,
    balance,
    users,
    tags,
    {
        name: 'userprofile',
        icon: SettingsIcon,
        list: UserProfile,
        options: { label: 'Settings' },
    },  
];

export const customerResources = [
    participants,
    bulk,
    usage,
    tags,
    {
        name: 'userprofile',
        icon: SettingsIcon,
        list: UserProfile,
        options: { label: 'Settings' },
    },
];

export const guestResources = [
    ForgotPasswordPage,
    SignUpPage
];
import {
    Datagrid,
    List,
    TextField,
    ReferenceField,
    DateField,
    SearchInput,
    NumberField,
    FilterButton,
    TopToolbar,
    useRecordContext
} from "react-admin";
import { Box, Chip, Typography } from "@mui/material";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PaymentIcon from '@mui/icons-material/Payment';
import SmsIcon from '@mui/icons-material/Sms';
import PhoneIcon from '@mui/icons-material/Phone';

const ListActions = () => (
    <TopToolbar>
        <FilterButton />
    </TopToolbar>
);

const listFilters = [
    <SearchInput source="q" alwaysOn placeholder="Search expenses..." />,
];

const ExpenseMethodField = () => {
    const record = useRecordContext();
    if (!record?.expense) return null;

    const getMethodIcon = (method) => {
        switch (method.toUpperCase()) {
            case 'SMS':
                return <SmsIcon fontSize="small" />;
            case 'VOICE':
                return <PhoneIcon fontSize="small" />;
            case 'CARD':
                return <PaymentIcon fontSize="small" />;
            default:
                return <ReceiptLongIcon fontSize="small" />;
        }
    };

    return (
        <Box display="flex" alignItems="center" gap={1}>
            {getMethodIcon(record.expense.method)}
            <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
                {record.expense.method}
            </Typography>
        </Box>
    );
};

const CostField = () => {
    const record = useRecordContext();
    if (!record) return null;

    return (
        <Box>
            <Typography variant="body2" color="error.main" fontWeight="medium">
                -<NumberField
                    source="expense.amount"
                    options={{
                        style: 'currency',
                        currency: record.currency || 'UGX',
                        minimumFractionDigits: 2
                    }}
                />
            </Typography>
            <Typography variant="caption" color="textSecondary">
                Balance: <NumberField
                    source="amount"
                    options={{
                        style: 'currency',
                        currency: record.currency || 'UGX',
                        minimumFractionDigits: 2
                    }}
                />
            </Typography>
        </Box>
    );
};

const RecipientField = () => {
    const record = useRecordContext();
    if (!record?.expense) return null;

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <PhoneIcon fontSize="small" color="action" />
            <Typography variant="body2" fontFamily="monospace">
                {record.expense.recipient}
            </Typography>
        </Box>
    );
};

const CustomDatagrid = ({ ...props }) => (
    <Datagrid
        {...props}
        bulkActionButtons={false}
        sx={{
            '& .RaDatagrid-headerCell': {
                backgroundColor: '#f5f5f5',
                fontWeight: 'bold',
            },
            '& .RaDatagrid-row': {
                '&:hover': {
                    backgroundColor: '#f9f9f9',
                },
            },
        }}
    >
        <DateField
            source="createdAt"
            label="Date"
            showTime
            options={{
                weekday: 'short',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            }}
        />
        <RecipientField source="expense.recipient" label="Recipient" />
        <ExpenseMethodField source="expense.method" label="Method" />
        <CostField label="Amount" />
    </Datagrid>
);

export const ListExpenses = (props) => (
    <List
        {...props}
        title="Expenses"
        sort={{ field: 'createdAt', order: 'DESC' }}
        filters={listFilters}
        actions={<ListActions />}
        sx={{
            '& .RaList-main': {
                boxShadow: 1,
                borderRadius: 2,
                overflow: 'hidden',
            },
        }}
    >
        <CustomDatagrid />
    </List>
);
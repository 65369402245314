import {
    Datagrid,
    List,
    TextField,
    DateField,
    SearchInput,
    SelectInput,
    FilterButton,
    EditButton,
    TopToolbar,
    useRecordContext
} from "react-admin";
import { Box, Chip, Avatar, Typography } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';

const ListActions = () => (
    <TopToolbar>
        <FilterButton />
    </TopToolbar>
);

const listFilters = [
    <SearchInput source="q" alwaysOn placeholder="Search by name or email..." />,
    <SelectInput
        source="role"
        choices={[
            { id: 'admin', name: 'Admin' },
            { id: 'user', name: 'User' },
        ]}
        sx={{ minWidth: 200 }}
    />,
];

const UserField = () => {
    const record = useRecordContext();
    if (!record) return null;

    return (
        <Box display="flex" alignItems="center" gap={2}>
            <Avatar sx={{ bgcolor: 'primary.main' }}>
                {record.name ? record.name[0].toUpperCase() : <PersonIcon />}
            </Avatar>
            <Box>
                <Typography variant="body1" fontWeight="medium">
                    {record.name}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                    {record.email}
                </Typography>
            </Box>
        </Box>
    );
};

const RoleField = () => {
    const record = useRecordContext();
    if (!record) return null;

    const getRoleColor = (role) => {
        switch (role.toLowerCase()) {
            case 'admin':
                return 'error';
            case 'user':
                return 'primary';
            default:
                return 'default';
        }
    };

    return (
        <Chip
            label={record.role}
            color={getRoleColor(record.role)}
            variant="outlined"
            size="small"
            sx={{
                textTransform: 'capitalize',
                fontWeight: 'medium'
            }}
        />
    );
};

const CustomDatagrid = ({ ...props }) => (
    <Datagrid
        {...props}
        bulkActionButtons={false}
        sx={{
            '& .RaDatagrid-headerCell': {
                backgroundColor: '#f5f5f5',
                fontWeight: 'bold',
            },
            '& .RaDatagrid-row': {
                '&:hover': {
                    backgroundColor: '#f9f9f9',
                },
            },
        }}
    >
        <UserField label="User" />
        <TextField 
            source="username" 
            label="Username"
            sx={{ fontWeight: 'medium' }}
        />
        <TextField 
            source="phone" 
            label="Phone"
        />
        <RoleField source="role" label="Role" />
        <DateField
            source="createdAt"
            label="Created"
            showTime
            options={{
                weekday: 'short',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            }}
        />
        <EditButton />
    </Datagrid>
);

export const ListUsers = (props) => (
    <List
        {...props}
        title="Users"
        sort={{ field: 'createdAt', order: 'DESC' }}
        filters={listFilters}
        actions={<ListActions />}
        sx={{
            '& .RaList-main': {
                boxShadow: 1,
                borderRadius: 2,
                overflow: 'hidden',
            },
        }}
    >
        <CustomDatagrid />
    </List>
);
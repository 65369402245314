import {
    SimpleForm,
    Create,
    Toolbar
} from "react-admin";
import { UploadBulk } from "../components/upload-bulk";

const PostEditToolbar = (props) => (
    <Toolbar>
        <UploadBulk content={props.tag}/>
    </Toolbar>
);

export const CreateParticipants = (props) => (
    

    <Create  {...props}>
        <SimpleForm  {...props} redirect={redirect} toolbar={<PostEditToolbar/>}>

            {/* <TextInput
                source="tag"
                label="Enter Tag"
                validate={[required()]}
            /> */}
            {/* <UploadBulk content={props.tag}/> */}
        </SimpleForm>
    </Create>
);

const redirect = () => `/participants`;

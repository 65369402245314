import React, { useState, useEffect } from 'react';
import { AppBar, Layout, useGetIdentity, useDataProvider } from 'react-admin';
import { Box, Typography, Chip, CircularProgress } from '@mui/material';
import { AccountBalanceWallet as WalletIcon } from '@mui/icons-material';

const CustomAppBar = () => {
    const { identity, isLoading: identityLoading } = useGetIdentity();
    const [balance, setBalance] = useState(null);
    const [loading, setLoading] = useState(true);
    const dataProvider = useDataProvider();

    useEffect(() => {
        const fetchBalance = async () => {
            try {
                setLoading(true);
                const response = await dataProvider.getList('usage', {
                    pagination: { page: 1, perPage: 1 },
                    sort: { field: 'updatedAt', order: 'DESC' },
                    filter: {}
                });
                
                // Get the current balance from the response
                const currentBalance = response.currentBalance ?? 0;
                setBalance(currentBalance);
            } catch (error) {
                console.error('Error fetching balance:', error);
                setBalance(0);
            } finally {
                setLoading(false);
            }
        };

        if (identity?.id && !identityLoading) {
            fetchBalance();
            // Refresh balance every 30 seconds
            const interval = setInterval(fetchBalance, 30000);
            return () => clearInterval(interval);
        }
    }, [identity, identityLoading, dataProvider]);

    return (
        <AppBar>
            <Box flex={1} />
            <Box 
                display="flex" 
                alignItems="center" 
                gap={2}
                sx={{ mr: 2 }}
            >
                <Chip
                    icon={<WalletIcon />}
                    label={
                        loading || identityLoading ? (
                            <CircularProgress size={16} color="inherit" />
                        ) : (
                            <Typography variant="body2">
                                Balance: {balance?.toLocaleString() || '0'} UGX
                            </Typography>
                        )
                    }
                    color="primary"
                    variant="outlined"
                />
            </Box>
        </AppBar>
    );
};

export const CustomLayout = (props) => (
    <Layout {...props} appBar={CustomAppBar} />
);

import React from "react";
import { Admin, Resource, CustomRoutes } from 'react-admin';
// import jsonServerProvider from 'ra-data-json-server';
import { theme } from "./theme";
import Dashboard from "./resources/dashboard";
import { authProvider } from "./providers/auth";
import { client } from "./providers/client";
import { dataProvider } from "./providers";
import { LoginPage } from "./login";
import { getResources } from "../src/resources/index";
import { ForgotPasswordPage } from "./forgot";
import { SignUpPage } from "./signup";
// import polyglotI18nProvider from 'ra-i18n-polyglot';
import { Route } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import { CustomLayout } from './layout';

// const dataProvider = jsonServerProvider('https://jsonplaceholder.typicode.com');
// const i18nProvider = polyglotI18nProvider(() => "en", {
//   allowMissing: true,
//   onMissingKey: (key, _, __) => key
// });

const App = () => (
  <ThemeProvider theme={theme}>
  <Admin
    title="Turbo"
    theme={theme}
    dashboard={Dashboard}
    authProvider={authProvider(client)}
    dataProvider={dataProvider}
    loginPage={LoginPage}
    layout={CustomLayout}
  // i18nProvider={i18nProvider} 
  >
    <CustomRoutes noLayout>
      <Route path="/forgot" element={<ForgotPasswordPage />} />
      <Route path="/signup" element={<SignUpPage />} />
    </CustomRoutes>

    {user =>
      getResources(user).map(resource => [<Resource {...resource} />])
    }
  </Admin>
  </ThemeProvider>
);

export default App;

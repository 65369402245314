import * as React from "react";
import { Notification, useNotify, useRedirect } from "react-admin";
import { Paper, Typography, Button, TextField } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import { client } from "./providers/client";
import { useRequestState } from "./resources/hooks/request";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  forgotPasswordLink: {
    color: "#5191b7",
    textDecoration: "none",
    textAlign: "center",
    flexGrow: 1,
  },
  main: {
    backgroundColor: theme.palette.secondary.main,
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    padding: 16,
    boxSizing: "border-box"
  },
  paper: {
    padding: 16,
    width: "100%",
    maxWidth: 420,
    margin: 8
  },
  verticalMargin: {
    margin: "1em 0"
  }
});

export const SignUpPage = () => {
  const classes = useStyles();
  const register = useRequestState(signUp);
  const redirect = useRedirect();
  const [email, setEmail] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  const notify = useNotify();

  function handleSubmit(event) {
    event.preventDefault();
    register
      .fetch({ email, username, password })
      .then(data => {
        notify(data.message);
        redirect('/login');
      })
      .catch(error => {
        notify(error.response?.data?.message || error.message, "error");
      });
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.main}>
        <img
          style={{ display: "block", margin: "0 auto" }}
          src="icon.png"
          alt="Logo"
          height="160"
        />
        <Paper className={classes.paper}>
          <form onSubmit={handleSubmit}>
            <div className={classes.verticalMargin}>
              <TextField
                autoFocus
                name="email"
                label={"Email address"}
                value={email}
                fullWidth
                onChange={event => setEmail(event.target.value)}
              />
            </div>

            <div className={classes.verticalMargin}>
              <TextField
                name="username"
                label={"Username"}
                type="username"
                value={username}
                fullWidth
                onChange={event => setUsername(event.target.value)}
              />
            </div>

            <div className={classes.verticalMargin}>
              <TextField
                name="password"
                label={"Password"}
                type="password"
                value={password}
                fullWidth
                onChange={event => setPassword(event.target.value)}
              />
            </div>

            <Button variant="contained" type="submit" color="primary" fullWidth>
              SignUp
            </Button>
          </form>
        </Paper>
        {/* <Typography>
          <a
            className={classes.forgotPasswordLink}
            href={`${publicUrl}/#/login`}
          // target="_blank"
          // rel="noopener noreferrer"
          >
            Login
          </a>
        </Typography> */}
        <div>
          <Typography
            component={Link}
            className={classes.forgotPasswordLink}
            to="/login"
          >
            Login
          </Typography>
        </div>
        <Notification />
      </div>
    </ThemeProvider>
  );
};

function signUp(email, username, password) {
  return client
    .post("/portal/users/new", {
      email,
      username,
      password
    })
    .then(response => response.data);
}

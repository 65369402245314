import {
    Datagrid,
    List,
    TextField,
    DateField,
    SearchInput,
    SelectInput,
    FilterButton,
    TopToolbar,
    useRecordContext,
    NumberField
} from "react-admin";
import { Box, Chip, Typography, Avatar } from "@mui/material";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PersonIcon from '@mui/icons-material/Person';

const ListActions = () => (
    <TopToolbar>
        <FilterButton />
    </TopToolbar>
);

const listFilters = [
    <SearchInput source="q" alwaysOn placeholder="Search by username or method..." />,
    <SelectInput
        source="status"
        choices={[
            { id: 'completed', name: 'Completed' },
            { id: 'pending', name: 'Pending' },
            { id: 'failed', name: 'Failed' },
        ]}
        sx={{ minWidth: 200 }}
    />,
    <SelectInput
        source="method"
        choices={[
            { id: 'bank', name: 'Bank Transfer' },
            { id: 'card', name: 'Credit Card' },
            { id: 'wallet', name: 'E-Wallet' },
        ]}
        sx={{ minWidth: 200 }}
    />,
];

const StatusField = () => {
    const record = useRecordContext();
    if (!record) return null;

    const getStatusColor = (status) => {
        switch (status.toLowerCase()) {
            case 'completed':
                return 'success';
            case 'pending':
                return 'warning';
            case 'failed':
                return 'error';
            default:
                return 'default';
        }
    };

    return (
        <Chip
            label={record.status}
            color={getStatusColor(record.status)}
            variant="outlined"
            size="small"
            sx={{
                textTransform: 'capitalize',
                fontWeight: 'medium'
            }}
        />
    );
};

const PaymentMethodField = () => {
    const record = useRecordContext();
    if (!record) return null;

    const getMethodIcon = (method) => {
        switch (method.toLowerCase()) {
            case 'bank':
                return <AccountBalanceIcon fontSize="small" />;
            case 'card':
                return <CreditCardIcon fontSize="small" />;
            case 'wallet':
                return <AccountBalanceWalletIcon fontSize="small" />;
            default:
                return <AccountBalanceIcon fontSize="small" />;
        }
    };

    return (
        <Box display="flex" alignItems="center" gap={1}>
            {getMethodIcon(record.method)}
            <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
                {record.method}
            </Typography>
        </Box>
    );
};

const UserField = () => {
    const record = useRecordContext();
    if (!record) return null;

    return (
        <Box display="flex" alignItems="center" gap={2}>
            <Avatar sx={{ bgcolor: 'primary.main', width: 32, height: 32 }}>
                {record.username ? record.username[0].toUpperCase() : <PersonIcon />}
            </Avatar>
            <Typography variant="body2" fontWeight="medium">
                {record.username}
            </Typography>
        </Box>
    );
};

const AmountField = () => {
    const record = useRecordContext();
    if (!record) return null;

    return (
        <Box>
            <Typography variant="body2" color="success.main" fontWeight="medium">
                +<NumberField
                    source="amount"
                    options={{
                        style: 'currency',
                        currency: record.currency || 'USD',
                        minimumFractionDigits: 2
                    }}
                />
            </Typography>
            <Typography variant="caption" color="textSecondary">
                {record.currency}
            </Typography>
        </Box>
    );
};

const CustomDatagrid = ({ ...props }) => (
    <Datagrid
        {...props}
        bulkActionButtons={false}
        sx={{
            '& .RaDatagrid-headerCell': {
                backgroundColor: '#f5f5f5',
                fontWeight: 'bold',
            },
            '& .RaDatagrid-row': {
                '&:hover': {
                    backgroundColor: '#f9f9f9',
                },
            },
        }}
    >
        <DateField
            source="createdAt"
            label="Date"
            showTime
            options={{
                weekday: 'short',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            }}
        />
        <UserField source="username" label="User" />
        <PaymentMethodField source="method" label="Payment Method" />
        <AmountField source="amount" label="Amount" />
        <StatusField source="status" label="Status" />
    </Datagrid>
);

export const ListCredit = (props) => (
    <List
        {...props}
        title="Credit"
        sort={{ field: 'createdAt', order: 'DESC' }}
        filters={listFilters}
        actions={<ListActions />}
        sx={{
            '& .RaList-main': {
                boxShadow: 1,
                borderRadius: 2,
                overflow: 'hidden',
            },
        }}
    >
        <CustomDatagrid />
    </List>
);
import MessageIcon from "@mui/icons-material/Message";
import { ListBulk } from "./list";
import { CreateBulk } from "./create";
// import { ShowBulk } from "./show";

export const bulk = {
    name: "bulk",
    options: {
        label: "Bulk"
    },
    icon: MessageIcon,
    list: ListBulk,
    create: CreateBulk,
    // show: ShowBulk
};
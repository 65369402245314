import GroupIcon from "@mui/icons-material/Group";
import { ListUsers } from "./list";
import { CreateUser, EditUser } from "./create-edit";
import { ShowUser } from "./show";

export const users = {
    name: "users",
    options: {
        label: "Users"
    },
    icon: GroupIcon,
    list: ListUsers,
    create: CreateUser,
    edit: EditUser,
    show: ShowUser
};
import moment from "moment";

import { numberWithCommas } from "./strings";

import {
  ChipField,
  FunctionField,
  useListContext
} from "react-admin";
import { styled } from '@mui/material/styles';

const useStyles = styled('div')(({ theme }) => ({
  button: {
    margin: '0 24px',
    position: 'relative',
  },
  input: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '50%',
  },
}));

export const TimestampField = ({ record, source }) => {
  const timestamp = record[source];

  if (!timestamp) {
    return <span>-</span>;
  }

  return (
    <span>
      {moment(timestamp).calendar({
        sameDay: "[Today], LT",
        nextDay: "[Tomorrow], LT",
        nextWeek: "dddd, LT",
        lastDay: "[Yesterday], LT",
        lastWeek: "DD/MM/YYYY, LT",
        sameElse: "DD/MM/YYYY, LT",
      })}
    </span>
  );
};

export const BalanceAmountField = ({ record, source, target }) => (
  <span>
    {record.currency} {numberWithCommas(record[source] ? record[source][target] : 0)}
  </span>
);

export const DicArrayField = ({ record, source, target }) => (
  <span>
    {record[source] ? record[source][target] : 0}
  </span>
);

export const TagField = ({ record, source, target }) => ( 
<FunctionField render={record => {
  record.tag?.map((tag, index) => <ChipField record={{ tag }} source="name" />)
}
} />
);

export const TagSelectInput = ({ record, source, target }) => {
  const classes = useStyles();
  return (
    <div className={classes.input}>
      {record => record.tag.map((tag, index) => <ChipField record={{ tag }} source="tag" />)}
    </div>)
};

export const BulkCountField = (props) => {
  const { ids, loaded } = useListContext(props);

  return loaded ? ids.length : null;
};
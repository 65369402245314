import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { ListExpenses } from "./list";

export const usage = {
    name: "usage",
    options: {
        label: "Expenses"
    },
    icon: ReceiptLongIcon,
    list: ListExpenses,
};
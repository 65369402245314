import React from "react";
import { useRefresh, useNotify } from "react-admin";
import UploadBulkIcon from "@mui/icons-material/CloudUpload";
import DownloadIcon from "@mui/icons-material/Download";
import { useRequestState } from "../hooks/request";
import { client } from "../../providers/client";
import { Button, Stack } from '@mui/material';

export const UploadBulk = () => {
  const uploadBulk = useRequestState(uploadExcelSheet);
  const notify = useNotify();
  const refresh = useRefresh();

  function onCapture() {
    const uploadInput = document.createElement("input");
    uploadInput.type = "file";
    uploadInput.accept = ".xlsx,.xls,.csv";
    uploadInput.multiple = true;
    uploadInput.onchange = handleUpload;
    uploadInput.click();
  }

  function handleUpload(e) {
    const formData = new FormData();
    const file = e.target.files[0]; // Only take the first file
    formData.append('file', file);

    return uploadBulk
      .fetch(formData)
      .then(() => {
        notify('Bulk upload successful!');
        refresh();
      })
      .catch(error => {
        notify(error?.response?.data?.message || 'Upload failed. Please check your file format.');
      });
  }

  function downloadTemplate() {
    const csvContent = `Phone,Tag,FirstName,LastName,DateOfBirth
+256700000000,VIP,John,Doe,1990-01-01
+256711111111,Regular,Jane,Smith,1992-05-15`;
    
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'bulk-upload-template.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  }

  return (
    <Stack direction="row" spacing={2}>
      <Button
        variant="contained"
        color="primary"
        onClick={onCapture}
        startIcon={<UploadBulkIcon />}
      >
        Upload Bulk
      </Button>
      <Button
        variant="outlined"
        color="primary"
        onClick={downloadTemplate}
        startIcon={<DownloadIcon />}
      >
        Download Template
      </Button>
    </Stack>
  );
};

async function uploadExcelSheet(content) {
  return await client.put("/portal/bulk/upload", content, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

import PaymentIcon from "@mui/icons-material/Payment";
import { ListCredit } from "./list";
import { CreateCredit } from "./create";
// import { ShowCredit } from "./show";

export const credit = {
    name: "credit",
    options: {
        label: "Credit"
    },
    icon: PaymentIcon,
    list: ListCredit,
    create: CreateCredit,
    // show: ShowCredit
};
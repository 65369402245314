import React from "react";
import {
    Show,
    SimpleShowLayout,
    TextField,
    DateField,
    ReferenceManyField,
    Pagination,
    Datagrid,
    TopToolbar,
    EditButton,
    DeleteButton,
    useRecordContext,
    ArrayField,
    SingleFieldList,
} from "react-admin";
import { Card, CardContent, Typography, Box, Grid, Chip } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
// import CakeIcon from '@mui/icons-material/Cake';
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';
import FavoriteIcon from '@mui/icons-material/Favorite';
import StarIcon from '@mui/icons-material/Star';

const getEventIcon = (type) => {
    switch (type) {
        case 'anniversary':
            return <FavoriteIcon />;
        case 'joining':
            return <WorkIcon />;
        case 'graduation':
            return <SchoolIcon />;
        case 'wedding':
            return <FavoriteIcon />;
        default:
            return <StarIcon />;
    }
};

const ParticipantTitle = () => {
    const record = useRecordContext();
    return record ? (
        <Typography variant="h6">
            Participant Details: {record.firstName} {record.lastName}
        </Typography>
    ) : null;
};

const PostCardActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <EditButton basePath={basePath} record={data} />
        <DeleteButton basePath={basePath} record={data} />
    </TopToolbar>
);

const BulkDataGrid = () => (
    <Datagrid 
        bulkActionButtons={false}
        sx={{
            '& .RaDatagrid-headerCell': {
                backgroundColor: '#f5f5f5',
                fontWeight: 'bold',
            },
            '& .RaDatagrid-row': {
                '&:hover': {
                    backgroundColor: '#f9f9f9',
                },
            },
        }}
    >
        <TextField 
            source="content" 
            label="Content"
            sx={{ 
                maxWidth: '300px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            }}
        />
        <TextField 
            source="method" 
            label="Method"
            sx={{
                '& .MuiChip-root': {
                    borderRadius: '4px',
                    backgroundColor: '#e3f2fd',
                },
            }}
        />
        <TextField 
            source="status" 
            label="Status"
            sx={{
                '& .success': { color: 'green' },
                '& .error': { color: 'red' },
                '& .pending': { color: 'orange' },
            }}
        />
        <TextField 
            source="amount" 
            label="Cost"
            sx={{ fontWeight: 'bold' }}
        />
        <DateField 
            source="createdAt" 
            label="Created"
            showTime
            options={{ 
                weekday: 'short',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            }}
        />
    </Datagrid>
);

const ParticipantInfo = () => {
    const record = useRecordContext();
    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Box display="flex" gap={2} alignItems="center" mb={2}>
                        <Typography variant="subtitle1" fontWeight="bold">
                            First Name:
                        </Typography>
                        <Typography>
                            <TextField source="firstName" />
                        </Typography>
                    </Box>
                    <Box display="flex" gap={2} alignItems="center" mb={2}>
                        <Typography variant="subtitle1" fontWeight="bold">
                            Last Name:
                        </Typography>
                        <Typography>
                            <TextField source="lastName" />
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box display="flex" gap={2} alignItems="center" mb={2}>
                        <Typography variant="subtitle1" fontWeight="bold">
                            Phone Number:
                        </Typography>
                        <Typography>
                            <TextField source="phoneNumber" />
                        </Typography>
                    </Box>
                    <Box display="flex" gap={2} alignItems="center">
                        <Typography variant="subtitle1" fontWeight="bold">
                            Date of Birth:
                        </Typography>
                        <Typography>
                            <DateField source="dateOfBirth" />
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" gap={2} alignItems="center">
                        <Typography variant="subtitle1" fontWeight="bold">
                            Created:
                        </Typography>
                        <Typography>
                            <DateField 
                                source="createdAt" 
                                showTime
                                options={{ 
                                    weekday: 'long',
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit'
                                }}
                            />
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

const EventsList = () => {
    const record = useRecordContext();
    if (!record?.events?.length) return null;

    return (
        <ArrayField source="events">
            <SingleFieldList>
                <Box display="flex" flexDirection="column" gap={2} width="100%">
                    {record.events.map((event, index) => (
                        <Card key={index} sx={{ width: '100%' }}>
                            <CardContent>
                                <Box display="flex" alignItems="center" gap={2}>
                                    <Box>
                                        {getEventIcon(event.type)}
                                    </Box>
                                    <Box flexGrow={1}>
                                        <Typography variant="subtitle1" fontWeight="bold">
                                            {event.title}
                                        </Typography>
                                        <Box display="flex" gap={2} alignItems="center">
                                            <DateField record={event} source="date" />
                                            <Chip 
                                                label={event.type} 
                                                size="small"
                                                color="primary"
                                                variant="outlined"
                                            />
                                            {event.isRecurring && (
                                                <Chip 
                                                    label="Recurring" 
                                                    size="small"
                                                    color="secondary"
                                                    variant="outlined"
                                                />
                                            )}
                                        </Box>
                                        {event.description && (
                                            <Typography variant="body2" color="textSecondary" mt={1}>
                                                {event.description}
                                            </Typography>
                                        )}
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                    ))}
                </Box>
            </SingleFieldList>
        </ArrayField>
    );
};

export const ShowParticipant = (props) => (
    <Show 
        actions={<PostCardActions />} 
        {...props}
        title={<ParticipantTitle />}
    >
        <SimpleShowLayout>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Personal Information
                            </Typography>
                            <ParticipantInfo />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Box display="flex" alignItems="center" gap={1} mb={2}>
                                <EventIcon />
                                <Typography variant="h6">
                                    Personal Events
                                </Typography>
                            </Box>
                            <EventsList />
                        </CardContent>
                    </Card>
                </Grid>
                
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Bulk Messages History
                            </Typography>
                            <ReferenceManyField
                                pagination={<Pagination />}
                                label="Bulks"
                                reference="bulk"
                                target="participantId"
                                sort={{ field: "createdAt", order: "DESC" }}
                            >
                                <BulkDataGrid />
                            </ReferenceManyField>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SimpleShowLayout>
    </Show>
);

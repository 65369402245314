// import React, { useEffect, useState } from "react";
// import { Edit, SimpleForm, TextInput } from "react-admin";
// import { backendUrl } from "../../../config";

// const UserProfile = (props) => {
//   const [userProfile, setUserProfile] = useState(null);
//   const userID = localStorage.getItem("user_id");

//   useEffect(() => {
//     if (userID) {
//       fetchUserProfile(userID);
//     } else {
//       console.log("UserID not found in local storage");
//     }
//   }, [userID, ]);

//   const fetchUserProfile = async (id) => {
//     try {
//       const { data, error } = await getUserProfile(id);
//       if (data) {
//         setUserProfile(data);
//       } else if (error) {
//         console.error("Error fetching user profile:", error.message);
//       }
//     } catch (error) {
//       console.error("Error fetching user profile:", error.message);
//     }
//   };

//   const getUserProfile = async (id) => {
//     try {
//       const response = await fetch(`${backendUrl}/portal/userprofile/${id}`); // Replace with your API endpoint
//       const data = await response.json();
//       return { data };
//     } catch (error) {
//       return { error };
//     }
//   };

//   if (!userProfile) return <div>Loading...</div>;

//   return (
//     <Edit title="Settings" {...props} id={userID}>
//       <SimpleForm initialValues={userProfile}>
//         <TextInput source="username" disabled fullWidth />
//         <TextInput source="name" fullWidth />
//         <TextInput source="email" fullWidth />
//         <TextInput source="phone" fullWidth />
//         <TextInput source="country" fullWidth />
//       </SimpleForm>
//     </Edit>
//   );
// };

// export { UserProfile };

import React, { useEffect, useState } from "react";
import { Edit, SimpleForm, TextInput } from "react-admin";
import { backendUrl } from "../../../config";

const fetchUserProfile = async (id) => {
  try {
    const response = await fetch(`${backendUrl}/portal/userprofile/${id}`);
    const data = await response.json();
    return { data };
  } catch (error) {
    return { error };
  }
};

const UserProfile = (props) => {
  const [userProfile, setUserProfile] = useState(null);
  const userID = localStorage.getItem("user_id");

  useEffect(() => {
    if (userID) {
      async function fetchData() {
        const { data, error } = await fetchUserProfile(userID);
        if (data) {
          setUserProfile(data);
        } else if (error) {
          console.error("Error fetching user profile:", error.message);
        }
      }
      fetchData();
    } else {
      console.log("UserID not found in local storage");
    }
  }, [userID]);

  if (!userProfile) return <div>Loading...</div>;

  return (
    <Edit title="Settings" {...props} id={userID}>
      <SimpleForm initialValues={userProfile}>
        <TextInput source="username" disabled fullWidth />
        <TextInput source="name" fullWidth />
        <TextInput source="email" fullWidth />
        <TextInput source="phone" fullWidth />
        <TextInput source="country" fullWidth />
      </SimpleForm>
    </Edit>
  );
};

export { UserProfile };

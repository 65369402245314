import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

export const theme = createTheme({
  palette: {
    primary: {
      // main: "#860111"
      main: "#5191b7"
    },
    secondary: {
      // main: "#800000"
      main: "#eef0f3"
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2
  }
});

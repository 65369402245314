// import {
//     SimpleForm,
//     Create,
//     TextInput,
//     SelectInput,
//     Edit
// } from "react-admin";
// import { roles, customer } from "../roles";

// export const CreateUser = props => {
//     return (
//         <Create {...props}>
//             <Form {...props} />
//         </Create>
//     );
// };

// export const EditUser = props => {
//     return (
//         <Edit title={<Title />} {...props}>
//             <Form {...props} />
//         </Edit>
//     );
// };

// const Title = ({ record }) => record && `${record.name}`;

// export const Form = (props) => (
//     <Create {...props}>
//         <SimpleForm>
//             <TextInput source="username" />
//             <TextInput source="name" />
//             <TextInput source="email" />
//             <TextInput source="phone" />
//             <TextInput source="password" />
//             <SelectInput
//                 source="role"
//                 choices={
//                     props.permissions?.role === customer
//                         ? roles.filter(role => [customer].includes(role.id))
//                         : roles
//                 }
//             />
//         </SimpleForm>
//     </Create>
// );

import React from "react";
import {
    Create,
    Edit,
    SimpleForm,
    TextInput,
    PasswordInput,
    SelectInput,
} from "react-admin";
import {
    roles,
    customer,
} from "../roles";

export const CreateUser = props => {
    return (
        <Create {...props}>
            <Form {...props} />
        </Create>
    );
};

export const EditUser = props => {
    return (
        <Edit title="Edit User" {...props}>
            <Form {...props} />
        </Edit>
    );
};

export const Form = ({ permissions, ...props }) => {
    return (
        <SimpleForm {...props} redirect={redirect}>
            <TextInput source="username" />
            <TextInput source="name" />
            <TextInput source="email" />
            <TextInput source="phone" />
            <PasswordInput source="password" />
            <SelectInput
                source="role"
                choices={
                    props.permissions === customer
                        ? roles.filter(role => [customer].includes(role.id))
                        : roles
                }
            />
        </SimpleForm>
    );
};

const redirect = (basePath, _id) => {
    if (/profile/.test(window.location.search)) {
        return "/";
    }

    return basePath;
};
import React from "react";
import {
    Title,
    useGetList,
    useGetMany,
    Loading,
    Error,
    useTranslate,
} from "react-admin";
import {
    Grid,
    Box,
    Typography,
    Paper,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Divider,
    Card,
    CardContent,
} from "@mui/material";
import {
    Message as MessageIcon,
    People as PeopleIcon,
    Tag as TagIcon,
    WhatsApp as WhatsAppIcon,
    Phone as PhoneIcon,
    MonetizationOn as MoneyIcon,
} from "@mui/icons-material";

const MetricCard = ({ icon, title, value, subtitle }) => (
    <Paper elevation={2} sx={{ p: 2, height: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            {icon}
            <Typography variant="h6" sx={{ ml: 1 }}>
                {title}
            </Typography>
        </Box>
        <Typography variant="h4" component="div" sx={{ mb: 1 }}>
            {value}
        </Typography>
        {subtitle && (
            <Typography variant="body2" color="text.secondary">
                {subtitle}
            </Typography>
        )}
    </Paper>
);

const RecentActivity = ({ resource, icon: Icon, getTitle }) => {
    const { data, isLoading, error } = useGetList(
        resource,
        {
            pagination: { page: 1, perPage: 5 },
            sort: { field: 'createdAt', order: 'DESC' }
        }
    );

    if (isLoading) return <Loading />;
    if (error) return <Error />;
    if (!data) return null;

    return (
        <List>
            {data.map((item, index) => (
                <React.Fragment key={item.id}>
                    <ListItem>
                        <ListItemIcon>
                            <Icon color="primary" />
                        </ListItemIcon>
                        <ListItemText
                            primary={getTitle(item)}
                            secondary={new Date(item.createdAt).toLocaleDateString()}
                        />
                    </ListItem>
                    {index < data.length - 1 && <Divider />}
                </React.Fragment>
            ))}
        </List>
    );
};

const Dashboard = () => {
    const translate = useTranslate();
    const { data: bulkData, isLoading: bulkLoading } = useGetList(
        'bulk',
        {
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'createdAt', order: 'DESC' }
        }
    );

    const { data: participantsData, isLoading: participantsLoading } = useGetList(
        'participants',
        {
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'createdAt', order: 'DESC' }
        }
    );

    const { data: tagsData, isLoading: tagsLoading } = useGetList(
        'tags',
        {
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'createdAt', order: 'DESC' }
        }
    );

    if (bulkLoading || participantsLoading || tagsLoading) return <Loading />;

    const totalSMS = bulkData?.filter(b => b.method === 'SMS').length || 0;
    const totalWhatsApp = bulkData?.filter(b => b.method === 'WhatsApp').length || 0;
    const totalVoice = bulkData?.filter(b => b.method === 'Voice').length || 0;
    const totalAirtime = bulkData?.filter(b => b.method === 'Airtime').length || 0;

    return (
        <Box>
            <Title title={translate('Dashboard')} />
            
            <Grid container spacing={3}>
                {/* Metrics Section */}
                <Grid item xs={12} md={3}>
                    <MetricCard
                        icon={<PeopleIcon color="primary" />}
                        title="Total Participants"
                        value={participantsData?.length || 0}
                        subtitle="Registered participants"
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <MetricCard
                        icon={<MessageIcon color="primary" />}
                        title="SMS Sent"
                        value={totalSMS}
                        subtitle="Total SMS messages"
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <MetricCard
                        icon={<WhatsAppIcon color="primary" />}
                        title="WhatsApp Messages"
                        value={totalWhatsApp}
                        subtitle="Total WhatsApp messages"
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <MetricCard
                        icon={<TagIcon color="primary" />}
                        title="Active Tags"
                        value={tagsData?.length || 0}
                        subtitle="Total participant tags"
                    />
                </Grid>

                {/* Recent Activities Section */}
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Recent Bulk Operations
                            </Typography>
                            <RecentActivity
                                resource="bulk"
                                icon={MessageIcon}
                                getTitle={item => `${item.method} to ${item.phoneNumber || 'Multiple Recipients'}`}
                            />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Recent Participants
                            </Typography>
                            <RecentActivity
                                resource="participants"
                                icon={PeopleIcon}
                                getTitle={item => item.phoneNumber}
                            />
                        </CardContent>
                    </Card>
                </Grid>

                {/* Additional Metrics */}
                <Grid item xs={12} md={4}>
                    <MetricCard
                        icon={<PhoneIcon color="primary" />}
                        title="Voice Calls"
                        value={totalVoice}
                        subtitle="Total voice messages"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <MetricCard
                        icon={<MoneyIcon color="primary" />}
                        title="Airtime Sent"
                        value={totalAirtime}
                        subtitle="Total airtime transactions"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <MetricCard
                        icon={<MessageIcon color="primary" />}
                        title="Total Messages"
                        value={(totalSMS + totalWhatsApp + totalVoice + totalAirtime)}
                        subtitle="All communication channels"
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Dashboard;
import {
    Datagrid,
    List,
    TextField,
    ArrayField,
    DateField,
    TopToolbar,
    ExportButton,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    SearchInput,
    FilterButton,
    CreateButton,
    TextInput,
    SelectInput,
    ShowButton,
    DateInput
} from "react-admin";
import { BulkCountField } from "../components/fields";
import { UploadBulk } from "../components/upload-bulk";
// import { Box } from '@mui/material';

const participantFilters = [
    <SearchInput source="phoneNumber" alwaysOn />,
    <TextInput source="firstName" label="First Name" />,
    <TextInput source="lastName" label="Last Name" />,
    <SelectInput source="status" choices={[
        { id: 'active', name: 'Active' },
        { id: 'inactive', name: 'Inactive' },
    ]} />,
    <TextInput source="tag" label="Tag" />,
    <DateInput source="dateOfBirth" label="Date of Birth" />,
];

const ListActions = (props) => (
    <TopToolbar>
        <FilterButton />
        <CreateButton />
        {/* <UploadBulk content={props.tag} /> */}
        <ExportButton />
    </TopToolbar>
);

const CustomDatagrid = ({ ...props }) => (
    <Datagrid
        {...props}
        bulkActionButtons={false}
        sx={{
            '& .RaDatagrid-headerCell': {
                backgroundColor: '#f5f5f5',
            },
            '& .RaDatagrid-row': {
                '&:hover': {
                    backgroundColor: '#f9f9f9',
                },
            },
        }}
    >
        <TextField 
            source="phoneNumber" 
            label="Phone Number"
            sx={{ fontWeight: 'bold' }}
        />
        <TextField 
            source="firstName" 
            label="First Name"
        />
        <TextField 
            source="lastName" 
            label="Last Name"
        />
        <ReferenceArrayField
            reference="tags"
            source="tag"
            label="Tags"
        >
            <SingleFieldList>
                <ChipField 
                    source="name"
                    sx={{
                        '& .MuiChip-root': {
                            borderRadius: '4px',
                        },
                    }}
                />
            </SingleFieldList>
        </ReferenceArrayField>
        {/* <DateField 
            source="dateOfBirth" 
            label="Birthday"
        />
        <ArrayField label="Bulks" source="bulkId">
            <BulkCountField />
        </ArrayField> */}
        <DateField 
            source="createdAt" 
            label="Created"
            showTime
            options={{ 
                weekday: 'short',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            }}
        />
        <ShowButton label="View" />
    </Datagrid>
);

export const ListParticipants = (props) => (
    <List
        title="Participants"
        sort={{ field: "createdAt", order: "DESC" }}
        filters={participantFilters}
        actions={<ListActions />}
        sx={{
            '& .RaList-main': {
                boxShadow: 1,
                borderRadius: 2,
                overflow: 'hidden',
            },
        }}
        {...props}
    >
        <CustomDatagrid rowClick="show" />
    </List>
);